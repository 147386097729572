import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactNoSleep from "react-no-sleep";
import firebase from '../../firebase';
import { AuthContext } from '../auth';
import { ProfilePic, LogOutButton } from './styles';

const AppFooter = () => {
  const db = firebase.firestore();
  const dispatch = useDispatch();
  const { gameNumber, startedGamesCount, finishedGamesCount } = useSelector(
    (state) => state.gameReducer
  );
  const { currentUser } = useContext(AuthContext);

  db.collection('started')
    .doc('startedCount')
    .onSnapshot(function (doc) {
      if (startedGamesCount !== doc.data().count) {
        dispatch({ type: 'UPDATE_STARTED_COUNT', payload: doc.data().count });
      }
    });
  db.collection('finished')
    .doc('finishedCount')
    .onSnapshot(function (doc) {
      if (finishedGamesCount !== doc.data().count) {
        dispatch({ type: 'UPDATE_FINISHED_COUNT', payload: doc.data().count });
      }
    });

  const handleSingOut = () => {
    const confirm = window.confirm('Tem certeza que deseja sair?');
    if (confirm) firebase.auth().signOut();
  };

  return (
    <footer style={footerStyle} className="footer clearfix">
      <div
        className="position-relative"
        style={{
          display: 'flex',
          height: '24px',
          alignItems: 'center',
          marginLeft: '16px',
        }}
      >
        {gameNumber ? (
          `#${gameNumber}`
        ) : (
          <span style={footerTextStyle}>
            developed by&nbsp;
            <a
              style={{ color: '#8e8d2a' }}
              href="https://www.linkedin.com/in/pnxbill"
              target="_blank"
              rel="noopener noreferrer"
            >
              pnxbill
            </a>
          </span>
        )}
        {currentUser && (
          <>
            <LogOutButton onClick={handleSingOut}> SAIR</LogOutButton>
            <ProfilePic src={currentUser.photoURL} alt="profile-pic" />
          </>
        )}
      </div>
    </footer>
  );
};

export default AppFooter;

const footerStyle = {
  position: 'absolute',
  left: '0',
  bottom: '0',
  width: '100%',
  boxShadow: '1px 1px 13px 5px #00000017',
  backgroundColor: '#c3c3c7',
  borderTop: '1px solid #000000ab',
  color: '#484848 !important',
  zIndex: '222',
};

const footerTextStyle = {
  fontFamily: 'Rubik',
  color: '#525252',
  fontSize: '0.9em',
  fontWeight: 300,
};
