import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaHome, FaBullseye } from 'react-icons/fa';
import logo from '../layout/logo.png';
import { NavBar } from './styles';

const AppNavbar = () => {
  const gamesLoaded = useSelector(
    (state) => state.gameReducer.finishedGamesCount
  );
  const [path, setPath] = useState('/');

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      if (window.location.pathname !== path) setPath(window.location.pathname);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [path]);

  return (
    <NavBar loaded={gamesLoaded}>
      <img
        src={logo}
        width="100"
        height="113"
        alt="logo bridou"
        className="bridou-nav-logo"
      />

      {!gamesLoaded && (
        <div
          className="d-flex justify-content-center w-100"
          style={SpinnerContainer}
        >
          {' '}
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div style={containerStyle} onClick={() => setPath('/')}>
        <Link
          to="/"
          className="navigation-button"
          style={{ color: path === '/' ? '#debc72' : '#fff' }}
        >
          <FaHome style={{ marginRight: 5 }} /> Home
        </Link>
      </div>
      <div
        style={{ ...containerStyle, justifyContent: 'flex-end' }}
        onClick={() => setPath('/new')}
      >
        <Link
          to="/new"
          className="navigation-button"
          style={{
            color: path === '/new' ? '#debc72' : '#fff',
            marginRight: '20px',
          }}
        >
          <FaBullseye style={{ marginRight: 5 }} /> Game
        </Link>
      </div>
    </NavBar>
  );
};

const containerStyle = {
  display: 'flex',
  width: '50%',
  justifyContent: 'flex-start',
  fontSize: '23px',
  fontWeight: 600,
};

const SpinnerContainer = {
  position: 'absolute',
  right: '0',
  left: 0,
};

export default AppNavbar;
