import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { getRodadaBack } from "../../actions";

function Rodadas(props) {
  const symphony = () => {};

  return (
    <div className="mt-3">
      <div>
        {props.gameNumber
          ? console.log(
              props.statey.find(game => game.gameNumber === props.gameNumber)
            )
          : null}
        {symphony()}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { round, gameNumber } = state.gameReducer;
  return {
    statey: state.firestore.ordered.games,
    gameNumber,
    round
  };
};

export default compose(
  firestoreConnect([{ collection: "games" }]),
  connect(
    mapStateToProps,
    { getRodadaBack }
  )
)(Rodadas);
