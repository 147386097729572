import { setAfterRound } from '../localStorage';

const gameConfirm = (state) => {
  return (dispatch, getState, { getFirestore }) => {
    //async call
    const firestore = getFirestore();
    const { gameNumber, round } = getState().gameReducer;
    const firestoreListState = { ...getState().gameReducer, ...state };
    const oldState = getState().gameReducer;
    // const { finished } = getState().firestore.ordered;

    firestore
      .collection('started')
      .doc(`${gameNumber}`)
      .collection('rounds')
      .doc(`${round}`)
      .set(firestoreListState);

    // Adicionar estado ao localStorage;

    //adicionar pedidos|pontos|quantasfez ao player
    let players = state.players.map((player) => {
      let bailou;
      state.bailadores.forEach((bailador) =>
        player.name === bailador ? (bailou = true) : ''
      );
      return {
        ...player,
        pontos: [...player.pontos, bailou ? -1 : 10 + player.pedidoAtual],
        pedidos: [...player.pedidos, player.pedidoAtual],
        fez: [
          ...player.fez,
          state.quemFez.reduce((total, atual) => {
            return atual === player.name ? total + 1 : total + 0;
          }, 0),
        ],
      };
    });

    //somar pontos total | zerar pedido atual dos players
    players = players.map((player) => {
      return {
        ...player,
        pontosTotal: player.pontos.reduce((total, atual) => {
          return total + atual;
        }, 0),
        pedidoAtual: null,
      };
    });

    //rodar players para a proxima rodada
    players.push(players.shift());

    const fazOL =
      oldState.round === 6 &&
      oldState.playersNumber === 4 &&
      oldState.multiplier === 2
        ? true
        : false;

    let reduxState = {
      players,
      parcial: state.parcial,
      currentPlayer: players[0].name,
      dealer: players[oldState.playersNumber - 1].name,
      playing: false,
      round: oldState.round + 1,
      cards:
        oldState.round < 7
          ? fazOL
            ? 13
            : oldState.cards + oldState.multiplier
          : oldState.cards === 13
          ? 12
          : oldState.cards - oldState.multiplier,
    };

    const localStorageState = { ...firestoreListState, ...reduxState };
    delete localStorageState.startedGames;
    delete localStorageState.finishedGames;
    if (oldState.round === 13) {
      const fixPlayers = [...players];
      fixPlayers.unshift(fixPlayers.pop());
      reduxState = {
        ...reduxState,
        players: fixPlayers,
        playersNumber: oldState.playersNumber,
        round: oldState.round,
        cards: oldState.cards,
        terminou: true,
        parcial: true,
        asterisco: oldState.asterisco,
        timeStarted: oldState.timeStarted,
        multiplier: oldState.multiplier,
        timeEnded: new Date(),
        gameNumber: oldState.finishedGamesCount + 1, //transforma o gameNumber em terminado
      };
      //Adicionar o jogo aos terminados.
      firestore
        .collection('finished')
        .doc(`${reduxState.gameNumber}`)
        .set(reduxState);

      firestore.collection('started').doc(`${gameNumber}`).update(reduxState);

      firestore
        .collection('finished')
        .doc('finishedCount')
        .set({ count: oldState.finishedGamesCount + 1 });

      // Remvoer o jogo do localStorage;
      setAfterRound(localStorageState, true);

      //Remover o jogo dos iniciados.
      // firestore
      //   .collection("started")
      //   .doc(`${gameNumber}`)
      //   .delete();
    } else {
      firestore.collection('started').doc(`${gameNumber}`).update(reduxState);

      setAfterRound(localStorageState);
    }
    dispatch({
      type: 'CONFIRMAR_MAO',
      payload: reduxState,
    });
  };
};

export default gameConfirm;
