import styled from 'styled-components';

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  /* margin-top: 27rem; */
  margin-top: 2rem;
  align-self: center;
  /* margin: 0 auto; */
`;

export const CustomSignInButton = styled.div`
  display: flex;
  cursor: pointer;
  color: #3b5998;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma,
    HelveticaNeueW10-55Roma, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  width: 239px;
  height: 46px;
  border: 1px solid #6363634d;
  padding: 0;
  background: #0000009e;
  box-shadow: 1px 1px 8px 3px #0c1721eb;
  margin: 5px;

  :hover {
    background: rgba(59, 89, 152, 0.1);
  }

  .fb-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #1d1d1d7a
      /* i {
      background-color: #3b5998;
      color: #fff;
      font-family: Arial, sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
      height: 48px;
      width: 58px;
      float: left;
    } */
      svg {
      color: ${(props) =>
        props.provider === 'google' ? '#a9454794' : '#2261bf'};
      margin: 0 10px;
      font-size: 22px;
    }
  }

  .fb-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #c5c5c5;

    b {
      color: #d2b574;
    }
  }
`;

export const LoginForm = styled.form`
  width: 239px;
  input {
    margin: 5px 0;
    padding: 5px;
    box-shadow: 1px 1px 8px 3px #0b151ffc;
    background: #000000a6;
    border: 1px solid #212121;
    color: #fff;

    ::placeholder {
      color: #ad9f7d;
    }
  }
  button {
    height: auto;
    border: 1px solid #dcdada3d;
    padding: 3px;
    background: #d6d6d699;
    box-shadow: 1px 1px 8px 3px #00000054;
    margin: 10px 0;
    color: #000000;
    font-weight: 600;
    border-radius: 6px;
    width: 50%;
    align-self: flex-end;
  }
`;

export const GoogleLogo = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background: #f44336;
  background: linear-gradient(#ff3c2d, #3fb344);
  margin: 0 7px;

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: #010203;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 4;
  }
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 35%;
    height: 32%;
    background: #010203;
    right: 0;
    z-index: 10;
    bottom: 50%;
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
  }
  .g-line {
    width: 50%;
    height: 20%;
    background: #0091ea;
    position: absolute;
    right: 0;
    margin: auto;
    border-bottom-right-radius: 4px 20px;
    top: 0;
    bottom: 0;
    z-index: 15;
  }

  .yellow {
    width: 40%;
    height: 40%;
    position: absolute;
    left: -15%;
    bottom: 32%;
    margin: auto;
    background: #ffc107;
    transform: rotateZ(-48deg);
    -webkit-transform: rotateZ(-48deg);
    z-index: 3;
  }
  .green {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    border-radius: 0 0 100% 100%;
    background: #4caf50;
    z-index: 2;
  }
  .blue {
    position: absolute;
    width: 0px;
    height: 0px;
    right: 0;
    z-index: 30;
    top: 50%;
    z-index: 2;
    width: 35%;
    height: 32%;
    background: #0091ea;
    right: 0;
    z-index: 2;
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
  }
  .red {
    width: 81%;
    height: 50%;
    position: absolute;
    top: 0px;
    background: #f44336;
  }
`;
