

class Speech {
  constructor() {
    if (!('speechSynthesis' in window)) return;

    this.msg = new SpeechSynthesisUtterance();
    this.init()
  }

  init() {
    this.msg.lang = 'pt-BR';
    this.voices = window.speechSynthesis.getVoices().filter(v => v.lang === 'pt-BR');
    this.voice = this.voices[8] || this.voices.find(v => v.voiceURI.includes('Luciana')) || this.voices[Math.floor(Math.random() * this.voices.length)]
    this.msg.voice = this.voice;
  }

  play(text) {
    if (window.speechSynthesis.speaking) return;
    
    this.msg.text = text;
    window.speechSynthesis.speak(this.msg)
  }
}

const speech = new Speech()
export default speech