import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled.button`
  border: 1px solid #6363634d;
  height: 46px;
  font-weight: 600;
  cursor: pointer;
  background: #0000009e;
  color: #c5c5c5;
  width: 300px;
  margin-bottom: 32px;
  box-shadow: 1px 1px 8px 3px #0c1721eb;
`;
