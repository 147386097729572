//Action creator
export const changePlayersAmount = number => {
  // Return an action
  return {
    type: 'PLAYERS_AMOUNT',
    payload: number
  };
};

export const getRodadaBack = state => {
  return {
    type: 'GET_RODADA',
    payload: state
  };
};

export const startGame = state => {
  return (dispatch, getState, { getFirestore }) => {
    //async call
    const firestore = getFirestore();
    const { gameNumber } = state;
    const newState = {
      ...getState().gameReducer,
      ...state,
      timeStarted: new Date()
    };
    firestore
      .collection('started')
      .doc(`${gameNumber}`)
      .set(newState)
    firestore
      .collection('started')
      .doc('startedCount')
      .set({count: state.gameNumber})
      // .then(() => {
      //   dispatch({
      //     type: 'GAME_START',
      //     payload: newState
      //   });
      // });
      localStorage.removeItem("gameState");
      dispatch({
        type: 'GAME_START',
        payload: newState
      });
  };
};

export const playCards = state => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const newState = getState().gameReducer;
    const { players, playing } = state;

    dispatch({
      type: 'PLAY_CARDS',
      payload: state
    });

    if (!firestore) return;

    firestore
      .collection('started')
      .doc(`${newState.gameNumber}`)
      .update({ players, playing });
  };
};

export const rodarMao = players => {
  return {
    type: 'RODAR_MAO',
    payload: players
  };
};

export const rodarPlayers = players => {
  return {
    type: 'RODAR_PLAYERS',
    payload: players
  };
};

export const voltarPedidos = state => {
  return {
    type: 'VOLTAR_PEDIDOS',
    payload: state
  };
};

export const voltarUmPedido = state => {
  return {
    type: 'VOLTAR_UM_PEDIDO',
    payload: state
  };
};

export const confirmarMao = state => {
  return async (dispatch, getState, { getFirestore }) => {
    //async call
    const firestore = getFirestore();
    const { gameNumber, round } = getState().gameReducer;
    const newState = { ...getState().gameReducer, ...state };
    const oldState = getState().gameReducer;

    dispatch({
      type: 'CONFIRMAR_MAO',
      payload: newState
    });

    if (!firestore) return;

    firestore
      .collection('started')
      .doc(`${gameNumber}`)
      .collection('rounds')
      .doc(`${round}`)
      .set(oldState);

    firestore
      .collection('started')
      .doc(`${gameNumber}`)
      .set(oldState);

    //.then(() => {
    
    // });

    /*firestore.update({ collection: "games", doc: id }, jaison).then(() => {
      dispatch({
        type: "CONFIRMAR_MAO",
        payload: newState
      });
    });*/
  };
};

export const terminarJogo = players => {
  //Adicionar calls db aqui
  return {
    type: 'TERMINAR_JOGO',
    payload: players
  };
};

export const fecharParcial = parcial => {
  return {
    type: 'FECHAR_PARCIAL',
    payload: !parcial
  };
};

export const voltarMao = state => {
  return (dispatch, getState, { getFirestore }) => {
    const newState = {
      ...state,
      currentPlayer: state.players[0].name,
      dealer: state.players[getState().gameReducer.playersNumber - 1].name
    };

    dispatch({
      type: 'VOLTAR_MAO',
      payload: newState
    });
  };
};

export const addAsterisco = name => {
  return (dispatch, getState, { getFirestore }) => {
    //async call
    const firestore = getFirestore();
    const { gameNumber, asterisco } = getState().gameReducer;
    const updtAsterisco = [...asterisco, name];
    firestore
      .collection('started')
      .doc(`${gameNumber}`)
      .update({ asterisco: updtAsterisco });

    dispatch({
      type: 'ADD_ASTERISCO',
      payload: updtAsterisco
    });
  };
};

export const setVoltarMao = boolean => {
  return {
    type: 'SET_VOLTAR_MAO',
    payload: boolean
  };
};

export const novoJogo = state => {
  return (dispatch, getState, { getFirestore }) => {
    //async call
    const firestore = getFirestore();
    const { startedGamesCount } = getState().gameReducer;

    const { players, playersNumber, dealer, currentPlayer, newPlayers } = state;
    const newState = {
      players,
      playersNumber,
      dealer,
      currentPlayer,
      newPlayers,
      cards: 1,
      round: 1,
      playing: false,
      parcial: false,
      voltarMao: false,
      asterisco: [],
      gameNumber: startedGamesCount + 1,
      timeStarted: new Date()
    };
    firestore
      .collection('started')
      .doc(`${newState.gameNumber}`)
      .set(newState)
      .then(() => {
        dispatch({
          type: 'NOVO_JOGO',
          payload: newState
        });
      });
    firestore
      .collection('started')
      .doc('startedCount')
      .set({count: newState.gameNumber })
  };
};

export const mudarJogadores = state => {
  return {
    type: 'MUDAR_JOGADORES',
    payload: state.players
  };
};

export const getGameLastState = input => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: 'GET_STATE',
      payload: input
    });
  };
};

export const updateFinished = array => {
  return {
    type: 'UPDATE_FINISHED',
    payload: array
  };
};

export const updateStarted = array => {
  return {
    type: 'UPDATE_STARTED',
    payload: array
  };
};

export const setDobrada = (isDobrada) => {
  return {
    type: 'SET_DOBRADA',
    payload: isDobrada
  };
}
