import React from 'react';
import { connect } from 'react-redux';
import { fecharParcial, novoJogo, mudarJogadores } from '../../actions';
import { fixDate } from '../functions/';
import { FaPlay, FaStar } from 'react-icons/fa';
import windowSize from 'react-window-size';
import './placar.css';
import speech from '../functions/speechSynteshis';

function Placar(props) {
  let { timeStarted, timeEnded } = props;
  timeStarted = fixDate(timeStarted);
  timeEnded = fixDate(timeEnded);

  // const placar = calcularPlacar(props)

  let placar;

  const calcularPlacar = (props) => {
    placar = [...props.players]
      .map((player) => {
        const qntAsterisco = [...props.asterisco].reduce(
          (total, curVal) =>
            curVal === player.name ? (total += 1) : (total += 0),
          0
        );

        if (qntAsterisco < 2) return player;
        else {
          return {
            ...player,
            pontosTotal: player.pontosTotal - parseInt(qntAsterisco / 2) * 5, //Diminuindo dos pontos total de acordo com o número e asterisco.
          };
        }
      })
      .sort((a, b) => a.pontosTotal - b.pontosTotal)
      .reverse();

    return placar;
  };

  const Portrait = () => {
    return calcularPlacar(props).map((player, i, arr) => {
      let color;
      let backgroundColor;
      if (i === 0) {
        color = '#af8d3a';
        backgroundColor = '#c6ced3';
      } else if (i === arr.length - 1) {
        color = '#d2272cbf';
        backgroundColor = i % 2 === 0 ? '#c6ced3cf' : '#12283d69';
      } else if (i % 2 === 0) {
        color = '#3a4766';
        backgroundColor = '#c6ced3cf';
      } else {
        color = '#aeaeaeed';
        backgroundColor = '#12283d69';
      }

      return (
        <tr
          className="placar-portrait-row"
          style={{ color, backgroundColor }}
          key={player.id}
        >
          <td
            className="td-portrait-name"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {player.name}
            {player.name === 'Arthur' ? (
              <span
                role="img"
                aria-label="mala-emoji"
                style={{ fontSize: '0.9em', marginLeft: '5px' }}
              >
                💼
              </span>
            ) : null}
            {i === 0 ? <FaStar className="b-star" /> : null}
          </td>{' '}
          <td className="td-portrait-pontos">{player.pontosTotal}</td>
        </tr>
      );
    });
  };

  const Landscape = () => {
    let someth = () => {
      const arr = [];
      arr.push(<td />);
      for (let i = 0; i < props.round; i++) {
        if (props.round < 10 && i === 7) continue;
        arr.push(<td>{i + 1}</td>);
      }
      arr.push(<td />);
      return arr;
    };
    let something = (
      <tr key="trkey" style={{ fontWeight: 'bold' }}>
        {someth()}
      </tr>
    );
    const playersPointsArray = calcularPlacar(props).map((player) => {
      let lista = [];
      for (let x = 0; x < player.fez.length; x++) {
        lista.push(
          <td
            style={{
              ...borderLeft,
              color:
                player.pedidos[x] === player.fez[x] ? '#f0c869' : '#c35a5f',
            }}
          >
            {player.pedidos[x]}/{player.fez[x]}
          </td>
        );
      }

      return (
        <tr key={player.id} style={tableStyleLandscape}>
          <td
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
              paddingLeft: '5px',
            }}
          >
            {player.name}
          </td>
          {lista}
          <td style={{ ...borderLeft, fontWeight: 'bold' }}>
            {player.pontosTotal}
          </td>
        </tr>
      );
    });

    const combined = playersPointsArray.concat(something);
    combined.unshift(combined.pop());

    return combined;
  };

  const novoJogo = () => {
    const mesmosJogadores = window.confirm('Mesmos jogadores?');
    //Limpar dados dos jogadores
    const newPlayers = props.players.map((player) => {
      return {
        ...player,
        pedidoAtual: null,
        pedidos: [],
        fez: [],
        pontos: [],
        pontosTotal: 0,
        fezAtual: null,
      };
    });

    newPlayers.push(newPlayers.shift());

    const state = {
      players: newPlayers,
      playersNumber: newPlayers.length,
      dealer: newPlayers[newPlayers.length - 1].name,
      currentPlayer: newPlayers[0].name,
      newPlayers: false,
    };

    if (mesmosJogadores) {
      return props.novoJogo(state);
    }
    return props.mudarJogadores(state);
  };

  const dateHour = (h) => {
    if (h === 'started') return timeStarted.getHours();
    if (h === 'finished') return timeEnded.getHours();
  };

  const dateMinute = (h) => {
    let value;
    if (h === 'started') value = timeStarted.getMinutes().toString();
    if (h === 'finished') value = timeEnded.getMinutes().toString();
    return value.length === 1 ? '0'.concat(value) : value;
  };

  const handleVoice = () => {
    speech.play(
      placar.map(
        (p) =>
          p.name +
          (p.pontosTotal < 0
            ? `menos ${String(p.pontosTotal).slice(1)}`
            : p.pontosTotal
          ).toString()
      )
    );
  };

  return (
    <div
      className="container placar-table"
      style={{ marginTop: props.windowWidth > 500 ? '12px' : '12%' }}
    >
      {props.terminou ? (
        <div>
          <div className="time-started">
            Início{' '}
            <span className="font-weight-bold">
              {dateHour('started')}:{dateMinute('started')}
            </span>
          </div>
          <div className="time-ended">
            Fim{' '}
            <span className="font-weight-bold">
              {dateHour('finished')}:{dateMinute('finished')}
            </span>
          </div>
        </div>
      ) : null}

      <div className="placar-container">
        <button onClick={handleVoice}>
          <FaPlay />
        </button>
        {props.windowWidth > 500 ? (
          <table className="placar-landscape">{Landscape()}</table>
        ) : (
          <table className="placar-portrait">{Portrait()}</table>
        )}
      </div>

      <div className="text-center mt-3">
        <p className="text-left" style={{ color: '#ac5457' }}>
          <b style={{ color: '#d1a43e' }}>Asterisco:</b>{' '}
          {props.asterisco.join(', ')}
        </p>

        {props.round < 13 ? (
          <div>
            <p className="text-left" style={{ color: '#ffffff85' }}>
              <b>Obs:</b> Os pontos referentes a asterisco serão deduzidos ao
              final do jogo{' '}
            </p>
            <button
              className="btn btn-primary start-button w-100"
              onClick={props.fecharParcial.bind(this, true)}
            >
              OK
            </button>
          </div>
        ) : (
          <button
            className="btn start-button"
            style={{ color: 'white', width: '100%' }}
            onClick={novoJogo}
          >
            NOVO JOGO
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { players, round, asterisco, terminou, timeStarted, timeEnded } =
    state.gameReducer;
  const { games } = state.firestore.ordered;

  return {
    players,
    round,
    asterisco,
    games,
    terminou,
    timeStarted,
    timeEnded,
  };
};

export default connect(mapStateToProps, {
  fecharParcial,
  novoJogo,
  mudarJogadores,
})(windowSize(Placar));

//CSS

const tableStyleLandscape = {
  fontSize: '1rem',
  border: '1px solid #9c9c9c',
};

const borderLeft = {
  borderLeft: '1px solid #4a4a4a',
};
