import React, { Component } from 'react';
import { connect } from 'react-redux';
import VoltarPedidosButton from './VoltarPedidosButton';
import ConfigButton from './ConfigButton';
import { FaList, FaPlay } from 'react-icons/fa';
import { getBase64Image } from '../functions/convertImg';
import speech from '../functions/speechSynteshis';
import cardsToFeminine from '../functions/convertFeminine';

class HelperView extends Component {
  constructor(props) {
    super(props);
    this.baralhoIcon = undefined;

    this.state = {
      players: this.props.players,
      visible: 'hidden',
      opacity: '0',
      backgroundColor: '#292a285c',
    };
  }

  componentDidMount() {
    let baralhoIcon = localStorage.getItem('baralhoIcon');
    if (!baralhoIcon) {
      const img = document.getElementById('baralho-icon');
      const img64 = getBase64Image(img);
      localStorage.setItem('baralhoIcon', img64);
      baralhoIcon = img64;
    }
    const readyImage = document.getElementById('baralho-loaded');
    readyImage.src = 'data:image/png;base64,' + baralhoIcon;
  }

  changeVisible = () => {
    const currentVisible =
      this.state.visible === 'hidden' ? 'visible' : 'hidden';
    const opacity = this.state.opacity === '0' ? '1' : '0';
    const backgroundColor =
      this.state.backgroundColor === '#292a285c' ? '#cb9a33b5' : '#292a285c';
    this.setState({
      visible: currentVisible,
      opacity: opacity,
      backgroundColor: backgroundColor,
    });
  };

  changeVisibleDesktop = () => {
    const currentVisible =
      this.state.visible === 'hidden' ? 'visible' : 'hidden';
    const opacity = this.state.opacity === '0' ? '1' : '0';
    if (!this.isMobile())
      this.setState({ visible: currentVisible, opacity: opacity });
  };

  isMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i)
    ) {
      return true;
    }
  }

  playSound = () => {
    let sound;
    if (this.props.players.every((p) => !p.pedidoAtual)) {
      sound = 'Nenhum pedido ainda';
    } else {
      sound = this.props.players.reduce((acc, cur) => {
        if (!cur.pedidoAtual) return acc;
        return acc + cur.name + ' ' + cur.pedidoAtual + ', ';
      }, ' ');
    }
    speech.play(sound);
  };

  helperView = () => {
    const isTwoDigitsCards = this.props.cards > 9;
    const dealerName = this.props.dealer;
    return (
      <div style={helperStyle}>
        <div className="px-0 text-center">
          <ConfigButton />
          <h3
            onClick={() =>
              speech.play(
                `${dealerName} embaralha ${cardsToFeminine(
                  this.props.cards
                )} carta${this.props.cards === 1 ? '' : 's'}`
              )
            }
          >
            <img src="" alt="baralho" id="baralho-loaded" style={imgStyle} />
            <span
              style={{
                position: 'relative',
                right: isTwoDigitsCards ? '30px' : '27px',
                color: '#dca22b',
                fontSize: isTwoDigitsCards ? '75%' : '100%',
              }}
            >
              {this.props.cards}
            </span>
            <span
              style={{
                marginLeft: '-10px',
                color: '#eaac2bba',
                fontFamily: "'Rubik', sans-serif",
                fontWeight: '600',
                textShadow: '#333339 1.5px 1.5px',
              }}
            >
              {dealerName}
            </span>
          </h3>
        </div>

        <div className="px-0 text-center">
          <h3
            style={{
              display: 'inline-block',
              color: '#d3d3d3',
              fontFamily: "'Rubik', sans-serif",
            }}
          >
            Pedidos:{' '}
            <span style={{ color: '#dab349' }}>
              {this.props.players
                .map((player) =>
                  player.pedidoAtual !== null
                    ? (player = player.pedidoAtual)
                    : 0
                )
                .reduce((total, acumulado) => {
                  return total + acumulado;
                }, 0)}
            </span>
          </h3>
          {this.props.playing ? <VoltarPedidosButton /> : ''}
          <button
            style={{
              ...pedidosButtonStyle,
              backgroundColor: this.state.backgroundColor,
              right: 44,
            }}
            onClick={this.playSound}
            className="btn btn-primary"
          >
            <FaPlay />
          </button>
          <button
            style={{
              ...pedidosButtonStyle,
              backgroundColor: this.state.backgroundColor,
            }}
            onTouchStart={this.changeVisible}
            onTouchEnd={this.changeVisible}
            onClick={this.changeVisibleDesktop}
            className="btn btn-primary"
          >
            <FaList />
          </button>
          <div
            className="mx-3 my-3 pb-4"
            style={{
              visibility: this.state.visible,
              ...pedidosStyle,
              opacity: this.state.opacity,
            }}
          >
            {this.props.players.map((player) => (
              <div key={player.id} style={{ display: 'block' }}>
                {player.pedidoAtual !== null ? (
                  <h2 className="text-left mt-4 mx-3">
                    {player.name}: &nbsp;
                    <span style={{ float: 'right' }}>{player.pedidoAtual}</span>
                  </h2>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.helperView()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { players, cards, dealer, playing, round } = state.gameReducer;

  return {
    players: playing ? players : ownProps.players,
    cards,
    dealer,
    playing,
    round,
  };
};

export default connect(mapStateToProps)(HelperView);

//CSS
const helperStyle = {
  background: 'linear-gradient(-1200deg, #89889f7d, #3f47531f)',
  color: '#97d3ff',
  position: 'relative',
  paddingTop: '0.8rem',
  marginBottom: '0.8rem',
  borderBottom: '1px solid #fff2f21a',
  borderTop: '1.9px solid #b985852e',
  boxShadow: '0px 0px 20px 0px #00000080',
};

const pedidosStyle = {
  width: 'auto',
  //backgroundColor: "black",
  background: 'linear-gradient(#323c56e0, #000000d4 120%)',
  color: '#fff',
  textAlign: 'center',
  padding: '5px 0',
  borderRadius: '6px',
  boxShadow: '#000000bd 5px 10px 8px',

  /* Position the tooltip text - see examples below! */
  position: 'absolute',
  transition: 'opacity 1s, visibility 1s',
  opacity: '0',

  zIndex: '1',
  left: '0px',
  top: '0px',
};

const pedidosButtonStyle = {
  float: 'right',
  backgroundColor: '#292a285c',
  transition: 'background-color 0.5s',
  borderColor: '#2d13131a',
  bottom: '-1px',
  position: 'absolute',
  display: 'inline',
  right: '0px',
};

const imgStyle = {
  position: 'relative',
  bottom: '4px',
  width: '50px',
};
