import { combineReducers } from "redux";

import "firebase/firestore";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";


const liquid = {
  gameNumber: null,
  playersNumber: null,
  multiplier: null,
  players: null,
  cards: 1,
  round: 1,
  currentPlayer: false,
  playing: false,
  dealer: "",
  parcial: false,
  voltarMao: false,
  newPlayers: false,
  asterisco: [],
  finishedGames: false,
  startedGames: false,
  startedGamesCount: null,
  finishedGamesCount: null,
  isFetching: false
};

const gameReducer = (state = liquid, action) => {
  switch (action.type) {
    case "UPDATE_GAME_STATE": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "UPDATE_FINISHED": {
      return {
        ...state,
        finishedGames: [...action.payload].sort(
          (a, b) => a.gameNumber - b.gameNumber
        )
      };
    }
    case "UPDATE_STARTED": {
      return {
        ...state,
        startedGames: [...action.payload].sort(
          (a, b) => a.gameNumber - b.gameNumber
        )
      };
    }
    case "UPDATE_FINISHED_COUNT": {
      return {
        ...state,
        finishedGamesCount: action.payload
      };
    }
    case "UPDATE_STARTED_COUNT": {
      return {
        ...state,
        startedGamesCount: action.payload
      };
    }
    case "PLAYERS_AMOUNT": {
      const playersNumber = action.payload;
      return {
        ...state,
        playersNumber
      };
    }

    case "GET_RODADA": {
      return {
        ...state,
        ...action.payload
      };
    }

    case "GAME_START": {
      return {
        ...state,
        ...action.payload
      };
    }

    case "PLAY_CARDS": {
      const { players, playing } = action.payload;
      return {
        ...state,
        players,
        playing
      };
    }
    case "VOLTAR_UM_PEDIDO": {
      const { players, playing, currentPlayer } = action.payload;
      return {
        ...state,
        players,
        playing,
        currentPlayer
      };
    }
    case "VOLTAR_PEDIDOS": {
      const { players, playing, currentPlayer } = action.payload;
      return {
        ...state,
        players,
        playing,
        currentPlayer
      };
    }
    case "CONFIRMAR_MAO": {
      const {
        players,
        parcial,
        currentPlayer,
        dealer,
        playing,
        round,
        cards
      } = action.payload;
      return {
        ...state,
        players,
        parcial,
        currentPlayer,
        dealer,
        playing,
        round,
        cards
      };
    }
    case "TERMINAR_JOGO": {
      return {
        ...state,
        players: action.payload
      };
    }
    case "FECHAR_PARCIAL": {
      return {
        ...state,
        parcial: action.payload
      };
    }
    case "VOLTAR_MAO": {
      // const { players, playing, cards, round, voltarMao } = action.payload;
      return {
        ...state,
        ...action.payload
      };
    }

    case "SET_DOBRADA": {
      return {
        ...state,
        multiplier: action.payload ? 2 : 1,
        cards: action.payload ? 2 : 1
      };
    }

    case "ADD_ASTERISCO": {
      //const { asterisco } = action.payload;
      return {
        ...state,
        asterisco: action.payload
      };
    }

    case "SET_VOLTAR_MAO": {
      return {
        ...state,
        voltarMao: action.payload
      };
    }
    case "NOVO_JOGO": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "RODAR_PLAYERS":
      return {
        ...state,
        players: action.payload.players,
        currentPlayer: action.payload.currentPlayer
      };
    case "CHANGE_PLAYER_PEDIDO":
      return {
        ...state,
        players: action.payload,
        rodada: state.rodada + 1
      };
    case "CHANGE_PEDIDOS":
      return {
        ...state,
        players: action.payload
      };

    case "GET_STATE":
      return {
        ...state,
        ...action.payload,
        finishedGames: state.finishedGames
      };

    case 'IS_FETCHING': 
      return {
        ...state,
        isFetching: action.payload
      };
    
      

    case "MUDAR_JOGADORES":
      return {
        ...state,
        newPlayers: action.payload,
        parcial: false
      };

    case "RECOVER_GAME":
      return {
        ...state,
        ...action.payload
      };
    
    case "RESET_GAME":
      return {
        ...state,
        gameNumber: null, //call axios db
        playersNumber: null,
        players: null,
        cards: 1,
        round: 1,
        currentPlayer: false,
        playing: false,
        dealer: "",
        parcial: false,
        voltarMao: false,
        newPlayers: false,
        asterisco: [],
      }

    default:
      return state;
  }
};

export const firebaseConfig = {
  apiKey: "AIzaSyCEG2fPpGPHh6ED2_hhl9_XojcOZew_v1w",
  authDomain: "bridou.firebaseapp.com",
  databaseURL: "https://bridou.firebaseio.com",
  projectId: "bridou",
  storageBucket: "bridou.appspot.com",
  messagingSenderId: "197544828463",
  appId: "1:197544828463:web:5532bd25ba572da5"
};

// react-redux-firebase config
export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Init firebase instance
//firebase.initializeApp(firebaseConfig);
// Init firestore
//const firestore = firebase.firestore();

// Add reactReduxFirebase enhancer when making store creator
/*const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
  reduxFirestore(firebase) // <- needed if using firestore
)(createStore);*/

// Add firebase to reducers
export const rootReducer = combineReducers({
  gameReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
});

// Create initial state
export const initialState = {};

// Create store
/*const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  compose(
    reactReduxFirebase(firebase),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);*/

//const store = createStore(rootReducer, initialState);

//export default store;
