import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCEG2fPpGPHh6ED2_hhl9_XojcOZew_v1w",
  authDomain: "bridou.firebaseapp.com",
  databaseURL: "https://bridou.firebaseio.com",
  projectId: "bridou",
  storageBucket: "bridou.appspot.com",
  messagingSenderId: "197544828463",
  appId: "1:197544828463:web:5532bd25ba572da5"
};

firebase.initializeApp(config);
/*firebase.firestore().settings({
  timestampsInSnapshots: true
});*/

export default firebase;
