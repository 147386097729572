import styled from 'styled-components';

export const NavBar = styled.nav`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 20;
  background: ${(props) =>
    props.loaded
      ? 'linear-gradient(-90deg,#02090f,#000000 /* #3d4a6a */)'
      : '#000'};
  box-shadow: 0px 0px 8px 10px #00000087;
  /* max-height: ${(props) => (props.loaded ? '60px' : '100%')}; */
  height: ${(props) => (props.loaded ? '60px' : '100vh')};
  min-height: ${(props) => (props.loaded ? '60px' : '100vh')};
  /* transition: 1.5s ease-in-out; */
  animation: ${(props) => (props.loaded ? 'Loading 2s ease-in-out' : 'none')};
  border-bottom: 1px solid #ffffff12;

  @keyframes Loading {
    0% {
      background: #000;
      height: 100vh;
      min-height: 100%;
    }
    50% {
      background: transparent;
      min-height: 60px;
      height: 60px;
    }
    100% {
      background: #02090f;
    }
  }

  img {
    position: absolute;
    top: ${(props) => (props.loaded ? '0' : '30vh')};
    width: ${(props) => (props.loaded ? '80px' : '100px')};
    height: ${(props) => (props.loaded ? '86px' : '113px')};
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    transition: 1s ease-in-out;
    animation: ${(props) =>
      props.loaded ? 'moveToLeft 1000ms ease 1s 1 normal forwards' : 'none'};

    @keyframes moveToLeft {
      from {
        margin-right: 39.3vw;
      }
      to {
        margin-right: 70vw;
      }
    }
  }

  a {
    color: #fff;
    font-size: 15px;
    margin-left: 43vw;
    cursor: pointer;
    opacity: 0;
    animation: ${(props) =>
      props.loaded ? 'ShowButtons 1.5s ease-in-out 2s forwards' : 'none'};

    @keyframes ShowButtons {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const ProfilePic = styled.img`
  border-radius: 3px;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 34px;
  height: 34px;
  box-shadow: 1px 1px 10px 6px #000;
`;

export const LogOutButton = styled.small`
  color: dark;
  /* top: 2px; */
  position: absolute;
  right: 48px;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
`;
