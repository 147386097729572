import React, { useCallback, useState } from "react";
import { withRouter } from "react-router";
import firebase from "../../firebase";
import { CustomSignInButton } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";

const SingUp = ({ history }) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const [errorMessage, setError] = useState(null);
  const handleSingUp = useCallback(
    async e => {
      e.preventDefault();
      const { email, password } = e.target.elements;
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (err) {
        alert(err);
        console.log("err", err);
      }
    },
    [history]
  );

  const handleFacebookLogin = () => {
    // firebase.auth().getRedirectResult().then(function(result) {
    //     if (result.credential) {
    //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //       var token = result.credential.accessToken;
    //       // ...
    //     }
    //     // The signed-in user info.
    //     var user = result.user;
    //   }).catch(function(error) {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // The email of the user's account used.
    //     var email = error.email;
    //     // The firebase.auth.AuthCredential type that was used.
    //     var credential = error.credential;
    //     // ...
    //   });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        console.log("user", result.user);
        history.push("/new");
        // ...
      })
      .catch(function(error) {
        alert("catched");
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        setError(`${errorCode} - ${errorMessage} - ${email} - ${credential}`);
      });
  };

  const handleLoginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(providerGoogle)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;

        history.push("/new");
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        setError(`${errorCode} - ${errorMessage} - ${email} - ${credential}`);
        // ...
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        marginTop: "5rem"
      }}
    >
      <h1>Sign Up</h1>
      <form onSubmit={handleSingUp} className="d-flex flex-column">
        <input name="email" type="email" placeholder="Email..." />
        <input name="password" type="password" placeholder="Password..." />
        <button type="submit">Sign Up</button>
      </form>
      <br />
      <CustomSignInButton provider="facebook" onClick={handleFacebookLogin}>
        <div className="fb-icon-container">
          <FontAwesomeIcon icon={faFacebookF} />
        </div>
        <div className="fb-text-container">
          Logar com &nbsp;<b>facebook</b>
        </div>
      </CustomSignInButton>
      <CustomSignInButton provider="google" onClick={handleLoginWithGoogle}>
        <div className="fb-icon-container">
          <FontAwesomeIcon icon={faGoogle} />
        </div>
        <div className="fb-text-container">
          Logar com &nbsp;<b>Google</b>
        </div>
      </CustomSignInButton>
      <span style={{ color: "red" }}>{errorMessage}</span>
    </div>
  );
};

export default withRouter(SingUp);
