import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { startGame } from '../../actions';

class GetPlayersNames extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fill = [
      'Bill',
      'Frederico',
      'Arthur',
      'Pierre',
      'Pedro',
      'Ze Doido',
      'Fernando'
    ];
    this.play = [];
    for (let i = 1; i <= this.props.playersNumber; i++) {
      this.play.push({
        id: i,
        name: this.fill[i - 1],
        pedidoAtual: null,
        pedidos: [],
        fez: [],
        pontos: [],
        pontosTotal: 0,
        fezAtual: null
      });
    }
  }

  // Generate Players' names labels.
  askPlayersNames = () => {
    const askPlayersNames = [];
    for (let i = 1; i <= this.props.playersNumber; i++) {
      askPlayersNames.push(
        <div className='form-group' key={i + Math.random()}>
          <input
            type='text'
            placeholder={`Player ${i}`}
            name={i}
            className='form-control input-lg mb-3'
            key={i}
            onChange={this.onChange}
            style={{ backgroundColor: '#ffffffa8' }}
          />
        </div>
      );
    }
    return askPlayersNames;
  };

  playersArray = this.play;

  onChange = e => {
    this.play = this.play.map(player => {
      if (player.id === Number(e.target.name))
        return { ...player, name: e.target.value };
      return player;
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const state = {
      gameNumber: this.props.gameNumber,
      players: this.play,
      currentPlayer: this.play[0].name,
      dealer: this.play[this.play.length - 1].name
    };

    window.scrollTo(0,0)

    this.props.startGame(state);
  };

  fillNames = () => {
    for (let i = 1; i <= this.props.playersNumber; i++) {
      document.querySelector(`[name="${i}"]`).value = this.fill[i - 1];
    }
  };

  render() {
    return (
      <div className='get-players-names fadein'>
        <h2 className='text-center pt-3 my-0'>Nomes</h2>
        <h5 className='text-center mb-3'>(ordem de pedido)</h5>
        <form onSubmit={this.handleSubmit}>
          {this.askPlayersNames()}
          <div className='text-center'>
            <input
              type='submit'
              value='PLAY'
              className='btn btn-info btn-block start-button'
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { playersNumber, startedGamesCount } = state.gameReducer;
  return {
    playersNumber,
    gameNumber: startedGamesCount +1
  };
};

export default compose(
  firestoreConnect([{ collection: 'games' }]),
  connect(
    mapStateToProps,
    { startGame }
  )
)(GetPlayersNames);
