import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../auth/Login';
import SingUp from '../auth/SingUp';
import InitialScreen from '../homepage';
import Rodadas from '../game/Rodadas';
import NewGame from '../game/NewGame';
import { useSelector } from 'react-redux';

import { Container } from './styles';

const Routes = () => {
  const gamesLoaded = useSelector(
    (state) => state.gameReducer.finishedGamesCount
  );

  return (
    <Container loaded={gamesLoaded}>
      <Switch>
        <Route exact path="/" component={InitialScreen} />
        <Route exact path="/new" component={NewGame} />
        <Route path="/login" component={Login} />
        <Route path="/singup" component={SingUp} />
        <Route exact path="/rodadas" component={Rodadas} />
      </Switch>
    </Container>
  );
};

export default Routes;
