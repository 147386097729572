import React, { useRef } from 'react'
import Modal from '..'
import styled from 'styled-components'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

const Label = styled.h2`
  text-align: center;
  color: #fff;
  opacity: 0.9;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: transparent;
  border: none;
  color: white;
  font-weight: bold;
  border: 1px solid #585858;
`

const OkButton = styled(Button)`
  background: #173d17e8;
  height: 8rem;
  font-size: 3rem;
`

const ReturnButton = styled(Button)`
  background-color: #1f1f1f;
  height: 30px;
  margin-top: auto;
  opacity: 0.5;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ConfirmationModal = ({ onClick, isOpen, closeModal }) => {

  const modalRef = useRef() 

  const handleClick = () => {
    onClick()
    closeModal()
  }

  useOnClickOutside(modalRef, closeModal);

  return (
    <Modal isOpen={isOpen}>
      <Container ref={modalRef}>
        <Label className='mb-4'>Tem certeza? </Label>
        <OkButton onClick={handleClick}>SIM</OkButton>
        <ReturnButton onClick={closeModal}>VOLTAR</ReturnButton>
      </Container>
    </Modal>
  )
}

export default ConfirmationModal