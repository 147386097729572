import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDobrada } from '../../../actions';
import { Button, Container } from './styles';

class Dobrada extends Component {
  componentDidMount() {
    if (this.props.playersNumber > 4) this.props.setDobrada(false);
  }

  setDobrada = (isDobrada) => {
    this.props.setDobrada(isDobrada);
  };

  render() {
    return (
      <Container>
        <Button onClick={() => this.setDobrada(false)}>NORMAL</Button>
        <Button onClick={() => this.setDobrada(true)}>DOBRADA</Button>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { playersNumber } = state.gameReducer;

  return {
    playersNumber,
  };
};

export default connect(mapStateToProps, { setDobrada })(Dobrada);
