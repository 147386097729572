import React, { Component } from 'react';
import { connect } from 'react-redux';
import HelperView from './HelperView';
import { FaArrowLeft } from 'react-icons/fa';
import MaoController from './MaoController';
import './Mao.css';
import { Spring } from 'react-spring/renderprops';
import { confirmarMao, terminarJogo } from '../../actions';
import gameConfirm from '../../actions/GameConfirm';
import ConfirmationModal from '../modal/ConfirmationModal.js';
import speech from '../functions/speechSynteshis';

class Mao extends Component {
  constructor(props) {
    super(props);
    this.bailadores = [];
    this.state = {
      quemFez: [],
      cardsPlayed: 0,
      isConfirmModalOpen: false,
    };
  }

  handleVoice = () => {
    const isBridou = this.props.dealer === this.bailadores[0];

    speech.play(
      `${this.bailadores.toString()} ${
        this.bailadores.length > 1 ? 'bailaram' : isBridou ? 'bridou' : 'bailou'
      }`
    );
  };

  generateButtons = () => {
    if (this.state.cardsPlayed < this.props.cards) {
      return (
        <React.Fragment>
          {this.props.players.map((player) => {
            return (
              <button
                key={player.id}
                onClick={this.fezEssa.bind(this, player.name)}
                className="btn btn-primary my-2"
                style={namesButton}
              >
                {player.name}{' '}
                <span style={pedidosSpan}>{player.pedidoAtual || ''}</span>
              </button>
            );
          })}
        </React.Fragment>
      );
    } else {
      const filteredPlayers = this.props.players.filter((player) => {
        return (
          player.pedidoAtual !==
          this.state.quemFez.reduce((total, atual) => {
            return atual === player.name ? total + 1 : total + 0;
          }, 0)
        );
      });

      this.bailadores = filteredPlayers.map((player) => player.name); // Array com nome de quem bailou

      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ delay: 100, duration: 1000 }}
        >
          {(props) => (
            <div style={props}>
              <div className="p-3">
                {this.bailadores.length > 0 ? ( // GENIUS THINKING
                  <h2 style={{ color: '#ffffffd4' }} onClick={this.handleVoice}>
                    Bailou:
                  </h2>
                ) : (
                  <h3 style={{ color: '#fff' }}>Ninguém Bailou</h3>
                )}
                {/*bailadores*/}
                {filteredPlayers.map((player) => {
                  return (
                    <h4 key={player.id} style={{ color: '#d1272c' }}>
                      {player.name}
                    </h4>
                  );
                })}
              </div>
            </div>
          )}
        </Spring>
      );
    }
  };

  concluirRodada = () => {
    let players = [...this.props.players];

    const state = {
      players,
      bailadores: this.bailadores,
      quemFez: [...this.state.quemFez],
      parcial: this.props.round === 7 ? true : false,
    };

    //enviar para redux store array de players atualizado e rodar o jogo quando necessário.
    return this.props.gameConfirm(state);
  };

  fezEssa = (name, e) => {
    e.preventDefault();
    this.setState({
      quemFez: [...this.state.quemFez, name],
      cardsPlayed: this.state.cardsPlayed + 1,
    });
  };

  voltaUm = () => {
    const quemFez = [...this.state.quemFez];
    quemFez.pop();
    this.setState({
      quemFez: quemFez,
      cardsPlayed: this.state.cardsPlayed - 1,
    });
  };

  removeFez = (index) => {
    const quemFez = [...this.state.quemFez];
    quemFez.splice(index, 1);
    this.setState({ quemFez, cardsPlayed: this.state.cardsPlayed - 1 });
  };

  quemFezList = () => {
    return this.state.quemFez.map((player, index, arr) => {
      return (
        <span
          className=""
          style={{
            ...quemFezStyle,
            borderBottom:
              index === arr.length - 1 ? 'none' : '1px solid #cacaca6b',
          }}
          onClick={this.removeFez.bind(this, index)}
          key={index}
        >
          {player}
        </span>
      );
    });
  };

  backButton = () => {
    return (
      <button
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: '1.2rem',
        }}
        onClick={this.voltaUm}
      >
        <FaArrowLeft color="red" />
      </button>
    );
  };

  okButton = () => {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1, width: 1 }}
        config={{ delay: 1000, duration: 1200 }}
      >
        {(props) => (
          <div style={props}>
            <div
              className="text-right"
              style={{
                position: 'fixed',
                bottom: '25px',
                left: '0',
                width: '100%',
              }}
            >
              <button
                className="btn btn-success"
                style={OkButtonStyle}
                onClick={() =>
                  this.setState({
                    isConfirmModalOpen: !this.state.isConfirmModalOpen,
                  })
                }
              >
                OK
              </button>
            </div>
          </div>
        )}
      </Spring>
    );
  };

  render() {
    return (
      <div>
        <HelperView />
        <MaoController
          cards={this.props.cards}
          cardsPlayed={this.state.cardsPlayed}
        />

        <div className="fadein" style={{ display: 'flex' }}>
          <div className="col-6">{this.generateButtons()}</div>
          <div className="col-6 p-3">
            <div
              className="quem-fez"
              style={{
                boxShadow:
                  this.state.quemFez.length > 0
                    ? '1px 1px 6px 5px #000000db'
                    : 'none',
                border: 'none',
              }}
            >
              {this.quemFezList()}
            </div>
            {this.state.quemFez.length > 0 ? (
              <div className="text-right">{this.backButton()}</div>
            ) : (
              ''
            )}
          </div>
          {this.state.cardsPlayed === this.props.cards ? this.okButton() : null}
          <ConfirmationModal
            isOpen={this.state.isConfirmModalOpen}
            onClick={this.concluirRodada}
            closeModal={() => this.setState({ isConfirmModalOpen: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    playersNumber,
    players,
    cards,
    round,
    currentPlayer,
    dealer,
    cardsPlayed,
    parcial,
  } = state.gameReducer;

  return {
    playersNumber,
    players,
    cards,
    round,
    currentPlayer,
    dealer,
    cardsPlayed,
    parcial,
  };
};

export default connect(mapStateToProps, {
  confirmarMao,
  terminarJogo,
  gameConfirm,
})(Mao);

const namesButton = {
  backgroundImage: 'linear-gradient(-90deg,#d1272c ,#000 )',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  width: '100%',
  border: '0.5px aliceblue',
  fontWeight: 600,
  height: '36px',
  paddingLeft: '30px',
};

const pedidosSpan = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  borderTopRightRadius: '.25rem',
  borderTopLeftRadius: '.25rem',
  backgroundColor: '#500c0cb3',
  color: '#fff',
  border: 'none',
  fontWeight: 600,
};

const quemFezStyle = {
  display: 'block',
  textAlign: 'right',
  fontSize: '1.3rem',
  //border: '1px solid #d0d0d0',
  borderRadius: '3px',
  backgroundColor: '#211a1a91',
  color: 'white',
  fontWeight: '400',
  paddingRight: '10px',
};

const OkButtonStyle = {
  backgroundColor: '#00000096',
  color: '#d4ab40',
  //fontSize: "1.2rem",
  fontWeight: 'bold',
  boxShadow: '20px 0px 20px 0px #00000080',
  borderRadius: '5px',
  width: '100vw',
  margin: 'auto',
  fontSize: '20px',
  padding: '5px',
  border: 'none',
  borderTop: '2px solid #b09951',
  borderBottom: '2px solid #b09951',
};
