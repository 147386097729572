import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { compararApelidos } from "./functions";
import { FaSort, FaArrowDown, FaArrowRight } from 'react-icons/fa'
import firebase from '../../firebase';

export const StatusList = ({ games }) => {
  const [listShowing, toggleList] = useState(false);
  const [orderBy, changeOrder] = useState("wins");
  const [orderMenu, showOrder] = useState(false);
  const [descShowing, toggleDesc] = useState(null);
  const listHeader = useRef(0);
  const orderMenuRef = useRef(null);

  const dispatch = useDispatch();
  const { finishedGames, finishedGamesCount, isFetching }  = useSelector(state => state.gameReducer);

  // useEffect(() => {
    
  //   return () => {
  //     document.removeEventListener("touchstart", handleClickOutside);
  //   };
  // },[]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (orderMenuRef.current && !orderMenuRef.current.contains(event.target))
        showOrder(false);
    }
    document.addEventListener("touchstart", handleClickOutside, false);
    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  });

  useEffect(() => {
    if (!listShowing) toggleDesc(null);
  }, [listShowing]);

  useEffect(() => {
    if (finishedGames) {
      if (listShowing) {
        listHeader.current.style.backgroundColor = "#ffffffdb";
        listHeader.current.style.color = "#3a3a3a";
      } else {
        listHeader.current.style.backgroundColor = "#878a8c52";
        listHeader.current.style.color = "#fff";
      }
    }
  }, [listShowing, finishedGames]);

  

  if (!finishedGamesCount) return null;

  const handleOrder = e => {
    e.stopPropagation();
    showOrder(true);
  };

  const handleOrderBy = order => {
    changeOrder(order);
    showOrder(false);
  };

  const handleResult = players => {
    const nameCorrect = () => {
      const upperCaseRemoveSpace = players.map(player => {
        return {
          ...player,
          name:
            player.name[player.name.length - 1] === " "
              ? player.name.slice(0, -1).toUpperCase()
              : player.name.toUpperCase(),
          pedidosTotal: player.pedidos.reduce((acc, atual) => acc + atual)
        };
      });

      return compararApelidos(upperCaseRemoveSpace);
    };

    const playersSorted = () =>
      nameCorrect().sort((a, b) => {
        if (a.pontosTotal === b.pontosTotal) {
          if (a.pedidosTotal === b.pedidosTotal) return 0;
          if (a.pedidosTotal > b.pedidosTotal) return -1;
          else return 1;
        }
        return a.pontosTotal > b.pontosTotal ? -1 : 1;
      });

    const newPlayers = playersSorted();

    return {
      players: newPlayers,
      winner: newPlayers[0].name,
      mumu: newPlayers[newPlayers.length - 1].name,
      participantes: newPlayers.map(player => player.name)
    };
  };

  const newGames = finishedGames ? finishedGames.filter(jogo => jogo.players).map(game => {
    return ({
    ...game,
    ...handleResult(game.players)
  })}) : [];

  //Array com nome de todos os participantes até hoje;
  const playersArr = () => {
    const arr = [];
    newGames.forEach(game => {
      game.players.map(p => p.name).forEach(pl => arr.push(pl));
    });
    return [...new Set(arr)];
  };

  // Criando um objeto player com seus respectivos status.
  const playersStatus = playersArr()
    .map(player => {
      const playerObject = {
        name: player,
        participation: 0,
        wins: 0,
        loses: 0,
        winPercentage: 0,
        losePercentage: 0,
        participationPercentage: 0
      };

      newGames.forEach(game => {
        if (game.winner === player) playerObject.wins++;
        if (game.mumu === player) playerObject.loses++;
        if (game.participantes.includes(player)) playerObject.participation++;
      });

      const { participation, wins, loses } = playerObject;
      playerObject.winPercentage = (wins * 100) / participation;
      playerObject.losePercentage = (loses * 100) / participation;
      playerObject.participationPercentage =
        (participation / newGames.length) * 100;

      return playerObject;
    })
    .sort((a, b) => b[orderBy] - a[orderBy]);

  const handleOpening = async () => {
    const getFinishedGames = async () => {
      dispatch({type: 'IS_FETCHING', payload: true});
      const db = await firebase.firestore();
      await db.collection('finished').get().then( snapshot => {
        dispatch({ type: "UPDATE_FINISHED", payload: snapshot.docs.map(el => el.data()) });
      })
      dispatch({type: 'IS_FETCHING', payload: false})
    }
    if (!finishedGames) {
      await getFinishedGames();
    }
    toggleList(!listShowing)
  }

  const orderMenuItems = () => {
    return (
      <>
        <div
          className="order-menu-item"
          onClick={() => handleOrderBy("participation")}
          fontWeight={orderBy === "participation" ? "600" : "400"}
        >
          PARTICIPAÇÃO
        </div>
        <div
          className="order-menu-item"
          onClick={() => handleOrderBy("wins")}
          fontWeight={orderBy === "wins" ? "600" : "400"}
        >
          VITÓRIAS
        </div>
        <div
          className="order-menu-item"
          onClick={() => handleOrderBy("winPercentage")}
          fontWeight={orderBy === "winPercentage" ? "600" : "400"}
        >
          % VITÓRIAS
        </div>
        <div
          className="order-menu-item"
          onClick={() => handleOrderBy("loses")}
          fontWeight={orderBy === "loses" ? "600" : "400"}
        >
          DERROTAS
        </div>
        <div
          className="order-menu-item"
          onClick={() => handleOrderBy("losePercentage")}
          fontWeight={orderBy === "losePercentage" ? "600" : "400"}
        >
          % DERROTAS
        </div>
      </>
    );
  };

  const headerTitle = () => (
    <div
      ref={listHeader}
      onClick={handleOpening}
      style={{
        display: "flex",
        color: "#fff",
        fontWeight: "bold",
        backgroundColor: "#878a8c52",
        transition: "0.5s",
        alignItems: "center",
        padding: "0 5px",
        minHeight: "24px",
        maxHeight: "24px"
      }}
    >
      {listShowing ? <FaArrowDown style={{ marginRight: 5 }} /> : <FaArrowRight style={{ marginRight: 5 }}/>}
      {listShowing ? (
        <>
          <div style={{ flex: 5 }}>NOME</div>
          <div style={{ flex: 3 }}>V.</div>
          <div style={{ flex: 3 }}>D.</div>
          <div style={{ flex: 2 }}>T.</div>
          <div style={{ flex: 1, textAlign: "right" }} onClick={handleOrder}>
            <FaSort />
          </div>
        </>
      ) : (
      <div style={{ flex: 14 }}>{`STATUS${isFetching ? '...' : ''}`}</div>
      )}
    </div>
  );

  const handleDescription = (ind, player) => {
    return (
      <div className={`desc-status ${!ind ? "selected" : ""}`}>
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 5 }}>{`${player.participation} (${String(
          player.participationPercentage
        ).slice(0, 4)}% total)`}</div>
        <div style={{ flex: 3 }}>{`%${String(player.winPercentage).slice(
          0,
          4
        )}`}</div>
        <div style={{ flex: 3 }}>{`%${String(player.losePercentage).slice(
          0,
          4
        )}`}</div>
        <div style={{ flex: 2 }}></div>
        <div style={{ flex: 1 }}></div>
      </div>
    );
  };

  const handleList = () => {
    let newPlayers = [...playersStatus];
    // JOGADORES PRECISAM TER AO MENOS 15 JOGOS PARA MOSTRAR NA ORDENAÇÃO POR PORCENTAGEM
    if (orderBy === "winPercentage" || orderBy === "losePercentage") {
      newPlayers = newPlayers.filter(pl => pl.participation > 14);
    }
    return newPlayers.map((player, ind) => (
      <div
        className={`d-flex single-winner position-relative ${
          descShowing === player.name ? "selected" : ""
        }`}
        onClick={() => toggleDesc(player.name)}
        key={ind}
      >
        {descShowing === player.name && handleDescription(ind, player)}
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 5 }}>{player.name}</div>
        <div style={{ flex: 3 }}>{player.wins}</div>
        <div style={{ flex: 3 }}>{player.loses}</div>
        <div style={{ flex: 2 }}>{player.participation}</div>
        <div style={{ flex: 1 }}></div>
      </div>
    ));
  };

  // <div className="single-winner">
  //   {el.name}:&nbsp;{el.points}
  // </div>

  return (
    <div id="status-list">
      <div className="list-container">
        {headerTitle()}
        {listShowing && <div className="list-body">{handleList()}</div>}
        {orderMenu && (
          <div className="order-menu" ref={orderMenuRef}>
            {orderMenuItems()}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusList;
