import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePlayersAmount } from '../../actions';

class GetPlayersNumber extends Component {
  something = num => {
    this.props.changePlayersAmount(num);
  };

  //Generate buttons

  generateButtons = () => {
    const numbersButton = [7, 6, 5, 4, 3, 2];
    const bgColors = [
      '#d1272ccc',
      '#d1272cad',
      '#d1272c82',
      '#d1272c47',
      '#424c6640',
      '#424c661f'
    ];

    for (let num = 2; num <= 7; num++) {
      numbersButton.push(
        <button
          className='btn btn-lg btn-secondary btn-no-color'
          onClick={this.something.bind(this, numbersButton[num - 2])}
          key={numbersButton[num - 2]}
          style={{
            backgroundColor: bgColors[num - 2],
            borderColor: '#ffffffa8',
            width: `${54 - num * 2.5}%`,
            margin: '5px'
            //display: 'inline-block',
            //marginRight: '100%'
          }}
        >
          {numbersButton[num - 2]}
        </button>
      );
    }

    return numbersButton.slice(6);
  };

  render() {
    return (
      <div
        className='fadein'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: "calc(100% - 100px)"
        }}
      >
        <h2 style={{ color: '#fff', margin: '15% 0'}}>Quantos jogadores?</h2>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
          {this.generateButtons()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { playersNumber, cards } = state.gameReducer;
  return {
    playersNumber,
    cards
  };
};

export default connect(
  mapStateToProps,
  { changePlayersAmount }
)(GetPlayersNumber);
