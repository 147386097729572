import React, { useState, useEffect, useMemo } from 'react';
import firebase from '../../firebase';

export const AuthContext = React.createContext();

const Disneyland = [
  'ewsRsHT4TSXqI0xtEzc7KQuwvXT2', // Advogado Frederico Souza
  '3sr55uxr6DY8yb3E23e1dyRgWIu2', // Frederico B.Race
  'bFNiPd66d8NExblMjZ8hbhb9TZk1', // Luiz UK Santchos
  '1uuNf5YRRddcHSRvTSlorRl1IAq1', // Olivera Costa (Souza) Pedro
  'AdOmuTJALEfXgoTJXwwQzypu7bU2', // Deus do game
  'JkbzQxdJYxY3Scp9YIr07Jqnfe73', // Vendas no crediário
  'PdzlU3cgUAfZQT04xskEwbLBnfp2', // Diferentão 40minutes
];

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const isFromDisneyland = useMemo(
    () => currentUser && Disneyland.includes(currentUser.uid),
    [currentUser]
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setCurrentUser);
    console.log('USER: -> ', currentUser);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, isFromDisneyland }}>
      {children}
    </AuthContext.Provider>
  );
};
