import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  position: relative;
  margin-top: 60px !important;
  transition: ease-in 2s;
  transition-delay: 2s;
  opacity: ${(props) => (props.loaded ? '1' : '0')};
`;
