import React from "react";
import Pedidos from "./pedidos/Pedidos.js";
import Mao from "./Mao";
import { connect } from "react-redux";
import Placar from "./Placar";
import MudarJogadores from "./MudarJogadores";

const Game = props => {
  if (props.parcial) return <Placar />;
  if (props.newPlayers) return <MudarJogadores />;
  return props.playing ? <Mao /> : <Pedidos />;
};

const mapStateToProps = state => {
  const { playing, parcial, newPlayers } = state.gameReducer;

  return {
    playing,
    parcial,
    newPlayers
  };
};

export default connect(mapStateToProps)(Game);
