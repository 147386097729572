import React from 'react';
import GetPlayersNames from './GetPlayersNames';
import GetPlayersNumber from './GetPlayersNumber';
import { Redirect } from 'react-router';
import Game from './Game';
import { useSelector } from 'react-redux';
import IsDoubled from './dobrada/IsDoubled';

const NewGame = () => {
  const { playersNumber, players, startedGamesCount, multiplier, terminou } =
    useSelector((state) => state.gameReducer);
  if (!startedGamesCount) return <Redirect to="/" />;

  // const blockSerieB = !isFromDisneyland && finishedGamesCount === 999;

  // if (blockSerieB)
  //   return (
  //     <h2 style={{ marginTop: '20px', color: 'white' }}>
  //       LAMENTAMOS INFORMAR QUE NESTE MOMENTO A SÉRIE B NÃO PODERÁ UTILIZAR O
  //       APLICATIVO. VOLTAREMOS EM BREVE! VLW TMJ!!
  //     </h2>
  //   );
  if (!playersNumber) return <GetPlayersNumber />;
  if (!terminou && !multiplier) return <IsDoubled />;
  if (!players) return <GetPlayersNames />;

  return <Game />;
};

export default NewGame;
