import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Overlay = styled.div`
  z-index: 2022;
  top: 0;
  left: 0;
  background: #000000bd;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  display: flex;
  position: absolute;
`;

const Container = styled.div`
  width: 100%;
  background: #000;
  height: 300px;
  display: flex;
  position: absolute;
  top: ${(props) => (props.open ? 'calc(100% - 300px)' : '100%')};
  transition: 500ms;
  padding: 25px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #2d2d2d;
  box-shadow: 0px -1px 7px 1px #000000d1;
`;

const Modal = ({ children, isOpen }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setOpen(true), 1);
    }

    return () => setOpen(false);
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Overlay>
      <Container className="modal" open={open}>
        {children}
      </Container>
    </Overlay>,
    document.querySelector('body')
  );
};

export default Modal;
