import React, { Component } from "react";
import { connect } from "react-redux";
import { FaCog } from 'react-icons/fa'
import { voltarMao, addAsterisco } from "../../actions";
import { Spring } from "react-spring/renderprops";
import Modal from "react-modal";

class ConfigButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      modalIsOpen: false
    };
    this.playersComAsterisco = [];

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("touchstart", this.handleClickOutside);
  }
  openModal = () => {
    this.setState({ modalIsOpen: true, openPopUp: false });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = "#f00";
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.playersComAsterisco = [];
  };

  handleClick = e => {
    this.setState({
      openPopUp: !this.state.openPopUp
    });
  };

  handleClickOutside(e) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target) &&
      this.state.openPopUp
    ) {
      return this.setState({ openPopUp: false });
    }
    return;
  }

  voltarMão = e => {
    const temCerteza = window.confirm(
      `Voltar para mão anterior? ${
        this.props.round < 8 ? this.props.cards - 1 : this.props.cards + 1
      } cartas`
    );
    if (temCerteza) {
      const { players, cards, round } = this.props;
      let newP = [...players].map(player => {
        let { pedidos, pontos, fez } = { ...player };
        pedidos = pedidos.slice(0, -1);
        pontos = pontos.slice(0, -1);
        fez = fez.slice(0, -1);
        return { ...player, pedidos, pontos, fez };
      });

      newP.unshift(newP.pop());

      const newCards = round > 7 ? cards + 1 : cards - 1;

      newP = newP.map(player => {
        return {
          ...player,
          pontosTotal: player.pontos.reduce((total, atual) => {
            return total + atual;
          }, 0),
          pedidoAtual: null
        };
      });

      const state = {
        players: newP,
        playing: false,
        cards: newCards,
        round: round - 1,
        voltarMao: true
      };
      this.setState({ openPopUp: false });
      this.props.voltarMao(state);
    }
    return this.setState({ openPopUp: false });

    /*const newPlayers = players.map(player => {
      return {...player, }
    })*/
  };

  addAsterisco = e => {
    this.props.addAsterisco(e.target.name);
    this.closeModal();
    //console.log(e.target.value);
    //console.log(e.target.checked);
  };

  createCheckbox = () => {
    const { players } = this.props;
    return (
      <div className="fadein">
        {players.map(player => (
          <div key={player.name}>
            <button
              className="btn mx-auto m-2"
              onClick={this.addAsterisco}
              name={player.name}
              style={{
                width: "100%",
                color: "#fff",
                backgroundColor: "#424c66",
                borderColor: "#ffffffa8",
                boxShadow: "0px 0px 20px 0px #00000080"
              }}
            >
              {player.name}
            </button>
          </div>
        ))}
      </div>
    );
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <div style={containerStyle}>
          <button style={buttonStyle} onClick={this.handleClick}>
            <FaCog />
          </button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h1 style={{ fontFamily: "Trebuchet MS" }}>Asterisco</h1>
          {this.createCheckbox()}
        </Modal>
        {this.state.openPopUp ? (
          <Spring
            from={{ display: "none", color: "black" }}
            to={{
              display: "inline",
              color: "#a9b0e1"
            }}
          >
            {props => (
              <div style={{ ...props, zIndex: "2" }}>
                <div className="fadein" style={menuStyle}>
                  {this.props.round > 1 ? ( //Se o round não for o primeiro, habilitar botão de voltar mão.
                    <button
                      style={{
                        width: "100%",
                        color: "#fff",

                        border: "1px solid",
                        borderColor: "#ffffffa8",
                        fontWeight: "bold"
                      }}
                      className="px-0 py-2 w-100 bluez"
                      onClick={this.voltarMão}
                    >
                      VOLTAR
                    </button>
                  ) : null}

                  <button
                    style={{
                      width: "100%",
                      color: "#fff",
                      border: "1px solid",
                      borderColor: "#ffffffa8",
                      fontWeight: "bold",
                      borderTop: "0.4px"
                    }}
                    className="px-0 py-2 w-100 start-button"
                    onClick={this.openModal}
                  >
                    ASTERISCO
                  </button>
                </div>
              </div>
            )}
          </Spring>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { players, cards, round, playersNumber } = state.gameReducer;

  return {
    players,
    cards,
    round,
    playersNumber
  };
};

export default connect(mapStateToProps, { voltarMao, addAsterisco })(
  ConfigButton
);

const buttonStyle = {
  backgroundColor: "transparent",
  border: "none",
  color: "#8395a9",
  padding: "5px",
  fontSize: "1.3rem",
  width: "100%"
};

const containerStyle = {
  display: "inline",
  position: "absolute",
  left: "0",
  top: "0"
};

const menuStyle = {
  display: "inline-block",
  position: "absolute",
  top: "0",
  left: "40px",
  background: "#00000080 none repeat scroll 0% 0%",
  color: "inherit",
  padding: "10px",
  width: "150px",
  zIndex: "2",
  borderRadius: "5px",
  border: "1px solid #ffffff1a ",
  boxShadow: "0px 0px 20px 0px #00000080"
};

const customStyles = {
  overlay: {
    backgroundColor: "#000000a3"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#fffc none repeat scroll 0% 0%",
    border: "1px solid #fff"
  }
};

//https://github.com/clauderic/react-sortable-hoc
