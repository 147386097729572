export const removePropFromObject = (object, prop) => {
  const obj = { ...object };
  delete obj[prop];
  return obj;
};

export const fixDate = date => {
  if (typeof date === "string") return new Date(Date.parse(date));
  if (typeof date === "object") {
    if (typeof date.getMonth !== "function") return date.toDate();
    return date;
  }
};
