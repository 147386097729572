import React, { Component } from "react";
import { connect } from "react-redux";
import { rodarMao, playCards /*, setVoltarMao*/ } from "../../../actions";
import { firestoreConnect } from "react-redux-firebase";
import { FaAngleLeft, FaPlay } from 'react-icons/fa'
import HelperView from "../HelperView";
import "../GlobalButtons.css";
import { compose } from "redux";
import speech from "../../functions/speechSynteshis";

class Pedidos extends Component {
  state = {
    players: this.props.players,
    currentPlayer: this.props.currentPlayer
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.players !== this.props.players) {
      //Perform some operation
      const { players, currentPlayer } = nextProps;
      this.setState({ currentPlayer, players });
    }
  }

  isMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i)
    ) {
      return true;
    }
  }

  mudarPedido = (sobra, i, e) => {
    e.preventDefault();
    if (this.props.cards > this.props.multiplier && sobra === i) {
      this.playSound(sobra);
      return; //Block the button if player is dealer (last to choose).
    }
    let players = this.state.players.map(player => {
      if (player.name === this.state.currentPlayer)
        return (player = { ...player, pedidoAtual: Number(e.target.value) });
      return player;
    });
    players.push(players.shift());

    setTimeout(() => {
      this.setState({
        players: players,
        currentPlayer: players[0].name
      });
    }, 100);

    if (players[0].pedidoAtual !== null) {
      //If everybody has choosen, start playing
      setTimeout(() => {
        this.props.playCards({
          players: players,
          playing: true
        });
      }, 150);
    }
  };

  onTouchStart = (sobra, i, e) => {
    if (this.props.cards > this.props.multiplier && sobra === i) {
      this.playSound(sobra)
      return e.preventDefault();
    }
    e.target.style.transition = "";
    e.target.style.backgroundColor = "#cda64b";
    e.target.style.color = "#fff";
    e.target.style.boxShadow = "none";
    e.target.style.border = "3px solid #4d5571";
  };

  onTouchEnd = (sobra, i, e) => {
    if (this.props.cards > this.props.multiplier && sobra === i) return e.preventDefault();
    e.target.style.transition =
      "color 0.5s, background-color 0.5s, box-shadow 0.5s, border 0.5s";
    e.target.style.backgroundColor = "#fff3";
    e.target.style.color = "#f9c649";
    e.target.style.boxShadow = "#212121 5px 2px 8px";
    e.target.style.border = "3px solid #adadadcf";
  };

  nothing = e => {
    e.preventDefault();
  };

  playSound = (sobra) => {
    speech.play(`Não pode ${sobra}`)
  } 

  //Generate pedidos button
  generateButtons = () => {
    const buttonsArray = [];

    const backButton = (
      <div
        className=""
        style={{
          display: "inline-block",
          width: "33.333%",
          textAlign: "center",
          paddingBottom: "1em"
        }}
      >
        <button
          className="backButton"
          onClick={this.voltaUm} //{this.mudarPedido.bind(this, dispatch)}
        >
          <FaAngleLeft />
        </button>
      </div>
    );

    let buttonBlockedColor =
      this.props.cards > this.props.multiplier
        ? {
            border: "3px solid #3f3f3f",
            backgroundColor: "#353030",
            color: "#7a7a7b"
          }
        : {};
    let sobra;

    for (let i = 0; i <= this.props.cards; i++) {
      if (this.props.dealer === this.state.currentPlayer) {
        const totalPedidos = this.state.players.reduce((total, actual) => {
          return total + actual.pedidoAtual;
        }, 0);
        sobra = this.props.cards - totalPedidos;
      }
      buttonsArray.push(
        <div
          className=""
          key={i}
          style={{
            display: "inline-block",
            width: "33.333%",
            textAlign: "center",
            paddingBottom: "1em"
          }}
        >
          <button
            className="pedidos-btn"
            value={i}
            key={i}
            onClick={this.mudarPedido.bind(this, sobra, i)}
            onTouchStart={this.onTouchStart.bind(this, sobra, i)}
            onTouchEnd={this.onTouchEnd.bind(this, sobra, i)}
            style={sobra === i ? buttonBlockedColor : {}}
          >
            {i}
          </button>
          {}
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%'}}>
        {buttonsArray}
        {this.state.players[this.props.playersNumber - 1].pedidoAtual === null
          ? ""
          : backButton}
      </div>
    );
  };

  voltaUm = e => {
    e.preventDefault();
    const players = [...this.state.players];
    players[this.props.playersNumber - 1].pedidoAtual = null;
    players.unshift(players.pop());
    this.setState({
      players: players,
      currentPlayer: players[0].name
    });
  };

  handleVoice = () => {
    speech.play(`${this.state.currentPlayer} faça seu pedido`);
  }

  render() {
    return (
      <div>
        <HelperView players={this.state.players} />
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
          <button onClick={this.handleVoice} style={voiceButtonStyle}><FaPlay /></button>
          <h1
            className="text-center"
            style={{
              margin: "15px 0",
              fontFamily: "'Rubik', sans-serif",
              fontWeight: "600",
              color: "#ffffffad"
            }}
          >
            {this.state.currentPlayer}:
          </h1>
        </div>
        <div className="example fadein" style={center}>
          {this.generateButtons()}
          <div style={cuzao} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    playersNumber,
    players,
    playersArray,
    cards,
    round,
    currentPlayer,
    dealer,
    cardsPlayed,
    voltarMao,
    multiplier
  } = state.gameReducer;

  return {
    playersNumber,
    players,
    playersArray,
    cards,
    round,
    currentPlayer,
    dealer,
    cardsPlayed,
    voltarMao,
    state: state.gameReducer,
    multiplier
  };
};

export default compose(
  firestoreConnect([{ collection: "games" }]),
  connect(mapStateToProps, { rodarMao, playCards /*, setVoltarMao*/ })
)(Pedidos);

//CSS
const center = {
  textAlign: "justify",
};

const cuzao = {
  display: "inline-block",
  width: "100%"
};

const voiceButtonStyle = {
  border: 'none',
  padding: 5,
  background: 'transparent',
  color: 'white'
}
