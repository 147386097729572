import React, { Component } from "react";
import { connect } from "react-redux";
import { novoJogo } from "../../actions";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { FaPlus } from 'react-icons/fa'
import arrayMove from "array-move";
import "./MudarJogadores.css";

//Disponibilizar lista de nomes com botões para excluir o jogador do jogo. //DONE
//Se tiver menos de 7 players, gerar botão de adicionar players.
//Conseguir rodar players arrastando. //DONE

const SortableItem = sortableElement(
  ({ value, deleteIndex, delButton, index }) => (
    <li
      style={{
        position: "relative",
        border: "1px solid #d1a23a",
        paddingLeft: "10px",
        color: "#fff"
      }}
      index={index}
    >
      {value.name}
      {delButton ? null : (
        <button
          onClick={deleteIndex}
          className="btniz"
          style={{
            position: "absolute",
            right: "0",
            fontWeight: "bolder",
            color: "#d2272c",
            paddingRight: "10px",
            paddingLeft: "0",
            lineHeight: "1.2",
            fontSize: "0.85em"
          }}
        >
          x
        </button>
      )}
    </li>
  )
);
const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul
      className="list-of-players"
      style={{
        backgroundColor: "#0000006e",
        border: "1px solid #313e5436",
        boxShadow: "0px 0px 20px 0px #00000080"
      }}
    >
      {children}
    </ul>
  );
});

class MudarJogadores extends Component {
  constructor(props) {
    super(props);

    this.newPlayer = null;

    this.state = {
      newPlayer: null,
      players: props.newPlayers,
      deleteButtonAppearing: false
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ players }) => ({
      players: arrayMove(players, oldIndex, newIndex),
      deleteButtonAppearing: false
    }));
  };

  onChange = e => {
    this.setState({ newPlayer: e.target.value });
  };

  addNewPlayer = () => {
    const AddPlayer = e => {
      e.preventDefault();
      const players = [...this.state.players];
      this.state.newPlayer
        ? players.push({ name: this.state.newPlayer })
        : window.alert("Insira um nome");

      this.setState({ players, newPlayer: "" });
    };

    const input = () => {
      return (
        <div className="ml-0" style={{ marginRight: "0" }}>
          {this.state.players.length - 7 ? (
            <form
              onSubmit={AddPlayer}
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <input
                className="form-control initial-screen-input"
                type="text"
                onChange={this.onChange}
                value={this.state.newPlayer}
                placeholder="Add player"
              />
              <button
                type="submit"
                style={{
                  position: "absolute",
                  right: 0,
                  backgroundColor: "transparent",
                  border: "none"
                }}
              >
                <FaPlus style={{ color: "#fff", fontSize: "18px" }} />
              </button>
            </form>
          ) : null}
          <button
            className="btn start-button"
            style={confirmButtonStyle}
            onClick={this.handleOk}
          >
            CONFIRMAR
          </button>
        </div>
      );
    };
    return input();
  };

  handleOk = () => {
    const players = this.state.players.map((player, index) => {
      return {
        id: index + 1,
        name: player.name,
        fez: [],
        pedidos: [],
        pontos: [],
        pontosTotal: 0,
        pedidoAtual: null,
        fezAtual: null
      };
    });

    const state = {
      ...this.props.newPlayers,
      players: players,
      playersNumber: players.length,
      dealer: players[players.length - 1].name,
      currentPlayer: players[0].name,
      newPlayers: false
    };

    return window.confirm("Tem certeza?") ? this.props.novoJogo(state) : null;
  };

  removePlayer = (index, e) => {
    e.preventDefault();
    const players = [...this.state.players];
    players.splice(index, 1);
    this.setState({ players: players });
  };

  helperCont = () => {
    return <div className="help-div" style={helpDivStyle} />;
  };

  sortStart = ({ node, index, collection, isKey }, event) => {
    this.setState({ deleteButtonAppearing: true });
  };

  render() {
    const { players } = this.state;

    return (
      <div className="cont-div fadein" /*style={{ textAlign: "center" }}*/>
        <SortableContainer
          onSortEnd={this.onSortEnd}
          onSortStart={this.sortStart}
        >
          {players.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              deleteIndex={this.removePlayer.bind(this, index)}
              delButton={this.state.deleteButtonAppearing}
            />
          ))}
        </SortableContainer>
        {this.addNewPlayer()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { players, round, newPlayers } = state.gameReducer;

  return {
    players,
    round,
    newPlayers
  };
};

export default connect(mapStateToProps, { novoJogo })(MudarJogadores);

const helpDivStyle = {
  padding: "10vh 20vw"
};

const confirmButtonStyle = {
  color: "#fff",
  backgroundColor: "#424c66",
  borderColor: "#201919a8",
  width: "100%",
  marginTop: "20px",
  fontWeight: "bold"
};
