import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const RecoverGame = () => {
  const state = localStorage.getItem("gameState");
  const oldState = useSelector(state => state.gameReducer);
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch({ type: "RECOVER_GAME", payload: JSON.parse(state) });
  };
  if (!state || oldState.round > 1) return null;
  return (
    <div id="recover-game">
      <Link to="new">
        <small onClick={handleContinue}>Continuar partida...</small>
      </Link>
    </div>
  );
};

export default RecoverGame;
