import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FaUndo } from 'react-icons/fa'
import { voltarPedidos, voltarUmPedido } from "../../actions";

Modal.setAppElement("#root");

class VoltarPedidosButton extends Component {
  state = {
    modalIsOpen: false
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = "#f00";
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  voltarPedidos = quantos => {
    if (quantos === "todos") {
      const players = this.props.players.map(player => {
        return { ...player, pedidoAtual: null };
      });
      this.props.voltarPedidos({
        players,
        playing: false,
        currentPlayer: players[0].name
      });
    }
    if (quantos === "um") {
      const players = [...this.props.players].map((p, i, a) => {
        if (i === a.length - 1) return { ...p, pedidoAtual: null };
        return p;
      });
      players.unshift(players.pop());
      this.props.voltarUmPedido({
        players,
        playing: false,
        currentPlayer: players[0].name
      });
    }
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "0",
          padding: "5px",
          paddingBottom: "0px"
        }}
      >
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#9c0000",
            padding: "0px",
            fontSize: "1.2rem"
          }}
          onClick={this.openModal}
        >
          <FaUndo />
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h1 style={{ fontFamily: "Trebuchet MS" }}>Voltar Pedidos</h1>

          <button
            className="btn btn-danger m-1"
            onClick={this.voltarPedidos.bind(this, "todos")}
            style={{
              width: "100%",
              color: "#fff",
              backgroundColor: "#424c66",
              borderColor: "#ffffffa8",
              boxShadow: "0px 0px 20px 0px #00000080"
            }}
          >
            Voltar TODOS
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={this.voltarPedidos.bind(this, "um")}
            style={{
              width: "100%",
              color: "#fff",
              backgroundColor: "#424c66",
              borderColor: "#ffffffa8",
              boxShadow: "0px 0px 20px 0px #00000080"
            }}
          >
            Voltar UM
          </button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { players } = state.gameReducer;

  return {
    players
  };
};

export default connect(
  mapStateToProps,
  { voltarPedidos, voltarUmPedido }
)(VoltarPedidosButton);

const customStyles = {
  overlay: {
    backgroundColor: "#000000a3"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#fffc none repeat scroll 0% 0%",
    border: "1px solid #fff"
  }
};
