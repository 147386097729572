import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppNavbar from './layout/AppNavbar';
import AppFooter from './layout/AppFooter';
import { Provider } from 'react-redux';
import { rrfConfig, rootReducer, initialState } from '../store';
import { createStore, applyMiddleware } from 'redux';
import {
  getFirestore,
  createFirestoreInstance,
  reduxFirestore,
} from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { composeWithDevTools } from 'redux-devtools-extension';

import './App.css';
import thunk from 'redux-thunk';

import firebase from '../firebase';
import { AuthProvider } from './auth';
import baralhoIcon from '../icons/baralho_icon.png';
import Routes from './routes';

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ getFirestore })),
    reduxFirestore(firebase)
  )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

class App extends Component {
  ChangePlayersNumber = (num) => {
    this.setState({
      havePlayersNumber: true,
      playersNumber: num,
    });
  };

  render() {
    return (
      <AuthProvider>
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Router>
              <div className="App">
                <AppNavbar />
                <Routes />
                <AppFooter />
                <img
                  src={baralhoIcon}
                  width="50px"
                  height="50px"
                  id="baralho-icon"
                  style={{ display: 'none' }}
                  alt="img-loader"
                />
              </div>
            </Router>
          </ReactReduxFirebaseProvider>
        </Provider>
      </AuthProvider>
    );
  }
}

export default App;
