export const compararApelidos = players => {
  return players.map(player => {
    switch (player.name) {
      // ARTHUR
      case "MALA":
        return { ...player, name: "ARTHUR" };
      case "LEITS":
        return { ...player, name: "ARTHUR" };
      case "LEITINHO":
        return { ...player, name: "ARTHUR" };
      case "ARTUR":
        return { ...player, name: "ARTHUR" };
      case "ARTHURR":
        return { ...player, name: "ARTHUR" };
      case "LEITAO":
        return { ...player, name: "ARTHUR" };
      // OTAVIO
      case "BILUBS":
        return { ...player, name: "OTAVIO" };
      case "OTÁVIO LADEIRA":
        return { ...player, name: "OTAVIO" };
      case "OTÁVIO":
        return { ...player, name: "OTAVIO" };
      case "BILUBAS":
        return { ...player, name: "OTAVIO" };
      case "BILUBIS":
        return { ...player, name: "OTAVIO" };
      // MT
      case "MARCO TULIO":
        return { ...player, name: "MT" };
      case "MARCO TÚLIO":
        return { ...player, name: "MT" };
      case "DR. MT":
        return { ...player, name: "MT" };
      case "MARCO T":
        return { ...player, name: "MT" };
      // FRED
      case "FREDERICO":
        return { ...player, name: "FRED" };
      case "VIGARISTA":
        return { ...player, name: "FRED" };
      // ZÉ DOIDO
      case "ZE":
        return { ...player, name: "ZÉ DOIDO" };
      case "GARIBALDI":
        return { ...player, name: "ZÉ DOIDO" };
      case "SE DOIDO":
        return { ...player, name: "ZÉ DOIDO" };
      case "ZÉ GARIBA":
        return { ...player, name: "ZÉ DOIDO" };
      case "ZÉ GARIBALDI":
        return { ...player, name: "ZÉ DOIDO" };
      case "ZÉ":
        return { ...player, name: "ZÉ DOIDO" };
      case "ZÉ CAPOTE":
        return { ...player, name: "ZÉ DOIDO" };
      case "GUERIBALDE":
        return { ...player, name: "ZÉ DOIDO" };
      case "ZE DOIDO":
        return { ...player, name: "ZÉ DOIDO" };
      case "GARIBA":
        return { ...player, name: "ZÉ DOIDO" };
      case "FILIPE":
        return { ...player, name: "ZÉ DOIDO" };
      // PEDRIM
      case "PEDRINHO":
        return { ...player, name: "PEDRIM" };
      case "PEDRIN":
        return { ...player, name: "PEDRIM" };
      case "PUTUCO":
        return { ...player, name: "PEDRIM" };
      case "PEDRO":
        return { ...player, name: "PEDRIM" };
      // BILL
      case "BILOCA":
        return { ...player, name: "BILL" };
      case "BIL KILLED":
        return { ...player, name: "BILL" };
      // FAFÁ
      case "FAFA":
        return { ...player, name: "FAFÁ" };
      case "FABRÍCIO":
        return { ...player, name: "FAFÁ" };
      // GIRAIA
      case "LHAMA":
        return { ...player, name: "GIRAIA" };
      case "JIRAYA":
        return { ...player, name: "GIRAIA" };
      case "JIRAIA":
        return { ...player, name: "GIRAIA" };
      case "GIRARIA":
        return { ...player, name: "GIRAIA" };
      // FERNANDO
      case "BIGAO":
        return { ...player, name: "FERNANDO" };
      case "BIGGAO":
        return { ...player, name: "FERNANDO" };
      case "NANDEX":
        return { ...player, name: "FERNANDO" };
      // CAIO
      case "CAIO ISLÃ":
        return { ...player, name: "CAIO" };
      // PIERRE
      case "GAGAU":
        return { ...player, name: "PIERRE" };
      // HOMERO
      case "MARKIN":
        return { ...player, name: "HOMERO" };
      case "MARQUIN":
        return { ...player, name: "HOMERO" };
      // WALLACE
      case "NENÉM":
        return { ...player, name: "WALLACE" };

      // DEFAULT
      default:
        return player;
    }
  });
};
