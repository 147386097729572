import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import StatusList from './StatusList';
import RecoverGame from './RecoverGame';
import firebase from '../../firebase';
import Login from '../auth/Login';
import { AuthContext } from '../auth';

const InitialScreen = () => {
  const [input, changeInput] = useState('');
  const { finishedGamesCount, startedGamesCount } = useSelector(
    (state) => state.gameReducer
  );
  const dispatch = useDispatch();
  const db = firebase.firestore();
  const { currentUser } = useContext(AuthContext);

  const { push } = useHistory();

  useEffect(() => {
    if (!finishedGamesCount) {
      db.collection('started')
        .doc('startedCount')
        .get()
        .then((doc) => {
          const { count } = doc.data();
          dispatch({
            type: 'UPDATE_STARTED_COUNT',
            payload: count === 0 ? null : count,
          });
        });
      db.collection('finished')
        .doc('finishedCount')
        .get()
        .then((doc) => {
          const { count } = doc.data();
          dispatch({
            type: 'UPDATE_FINISHED_COUNT',
            payload: count === 0 ? null : count,
          });
        });
    }
  }, [finishedGamesCount, dispatch, startedGamesCount, db]);

  const handleGameNumber = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        margin: '24px 0 0px 20px',
      }}
    >
      <p style={{ color: '#bdbdbd', marginBottom: '0' }}>
        Iniciados: {startedGamesCount}
      </p>
      <Link to="/login">
        <p style={{ color: '#bdbdbd', marginBottom: '0' }}>
          Finalizados: {finishedGamesCount}
        </p>
      </Link>
    </div>
  );

  const fetchState = (e) => {
    e.preventDefault();
    const state = input;
    const gameNumber = Number(state.substr(1));
    if (isNaN(state.charAt(0))) {
      if (state.charAt(0) === 'f' && gameNumber <= finishedGamesCount) {
        return db
          .collection('finished')
          .doc(String(gameNumber))
          .get()
          .then((doc) => {
            dispatch({ type: 'UPDATE_GAME_STATE', payload: doc.data() });
            push('/new');
          });
      } else if (state.charAt(0) === 'i' && gameNumber <= startedGamesCount) {
        return db
          .collection('started')
          .doc(String(gameNumber))
          .get()
          .then((doc) => {
            dispatch({ type: 'UPDATE_GAME_STATE', payload: doc.data() });
            push('/new');
          });
      } else
        return window.alert(
          'Letra errada! Tente começar com "i" para jogos em andamento, ou "f" para jogos finalizados'
        );
    }

    return window.alert('Seu código deve começar com uma letra');
  };

  return (
    <div id="initial-screen">
      {handleGameNumber()}
      <div className="state-pass-input fadein">
        <form
          onSubmit={fetchState}
          style={{
            display: 'flex',
            width: '100%',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <input
            type="text"
            value={input}
            onChange={({ target: { value } }) => changeInput(value)}
            className="form-control input-lg initial-screen-input"
            // style={{ width: '100%' }}
          />
          <button
            type="submit"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#fff',
              position: 'absolute',
              right: 10,
              padding: 0,
            }}
          >
            <FaPlus />
          </button>
        </form>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <StatusList />
      </div>
      <button
        onClick={() => {
          dispatch({ type: 'RESET_GAME' });
          push('/new');
        }}
        style={{ marginTop: 12 }}
        className="btn-new-game"
      >
        NOVO JOGO
      </button>
      {/* <RecoverGame /> */}
      {!currentUser && <Login />}
      {/* <ConfirmationModal /> */}
    </div>
  );
};

export default InitialScreen;
