import React, { /* useCallback, */ useContext, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import firebase from '../../firebase';
import { AuthContext } from './index';
import {
  CustomSignInButton,
  /* LoginForm, */
  GoogleLogo,
  SignInContainer,
} from './styles';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebookF /* , faGoogle */
// } from "@fortawesome/free-brands-svg-icons";

const Login = ({ history }) => {
  // const providerFacebook = new firebase.auth.FacebookAuthProvider();
  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const [errorMessage, setError] = useState(null);
  /* const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      const { email, password } = e.target.elements;
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/new");
      } catch (err) {
        alert(err);
        console.log("err", err);
      }
    },
    [history]
  );
 */
  // const handleFacebookLogin = () => {
  //   firebase
  //     .auth()
  //     .signInWithPopup(providerFacebook)
  //     .then(function(result) {
  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       // var token = result.credential.accessToken;
  //       // The signed-in user info.
  //       // var user = result.user;
  //       console.log("user", result.user);
  //       history.push("/new");
  //       // ...
  //     })
  //     .catch(function(error) {
  //       alert("catched");
  //       // Handle Errors here.
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       // The email of the user's account used.
  //       var email = error.email;
  //       // The firebase.auth.AuthCredential type that was used.
  //       var credential = error.credential;
  //       // ...
  //       setError(`${errorCode} - ${errorMessage} - ${email} - ${credential}`);
  //     });
  // };

  const handleLoginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(providerGoogle)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;

        history.push('/new');
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        setError(`${errorCode} - ${errorMessage} - ${email} - ${credential}`);
        // ...
      });
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) return <Redirect to="/new" />;

  return (
    <SignInContainer>
      {/* <h1>Log in</h1>
      <LoginForm onSubmit={handleLogin} className="d-flex flex-column">
        <input name="email" type="email" placeholder="Email" />
        <input name="password" type="password" placeholder="Password" />
        <button type="submit">LOGAR</button>
      </LoginForm>
      <br /> */}
      {/* <CustomSignInButton provider="facebook" onClick={handleFacebookLogin}>
        <div className="fb-icon-container">
          <FontAwesomeIcon icon={faFacebookF} />
        </div>
        <div className="fb-text-container">
          Logar com &nbsp;<b>facebook</b>
        </div>
      </CustomSignInButton> */}
      <CustomSignInButton provider="google" onClick={handleLoginWithGoogle}>
        <div className="fb-icon-container">
          <GoogleLogo>
            <div className="g-line"></div>
            <span className="red"></span>
            <span className="yellow"></span>
            <span className="green"></span>
            <span className="blue"></span>
          </GoogleLogo>
        </div>
        <div className="fb-text-container">
          Logar com &nbsp;<b>Google</b>
        </div>
      </CustomSignInButton>
      <span style={{ color: 'red' }}>{errorMessage}</span>
      {/* <small onClick={() => history.push("/singup")}>Sing up...</small> */}
    </SignInContainer>
  );
};

export default withRouter(Login);
