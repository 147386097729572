import React from 'react';

export default function MaoController(props) {
  return (
    <div className='text-center' style={{ display: 'block' }}>
      <span
        className='text-center font-weight-bold'
        style={{ color: '#dadbdc' }}
      >
        Mão: {props.cardsPlayed} / {props.cards}
      </span>
    </div>
  );
}
